import { isEmpty, isNil, isUndefined } from 'lodash';
import { convertUTCDateToLocalDate } from './dateAndTimeUtils';
import { convertToLocalDateTime } from 'components/Common/TimeFunctions';
import { format, parse, intlFormatDistance, formatDistance } from 'date-fns';
import { getPercentage } from './numberUtils';
import { DYNAMIC } from 'constants/constants';

export const getRiskRatingOverviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskRating',
      'RiskRatingScore',
      'RatingSummary',
      'ImpactLevelPills',
      'RiskRatingRecommendedAction',
      'Impact',
      'ImpactLevel',
      'Likelihood',
      'LikelihoodLevel',
      'LikelihoodPillsDataStorage',
      'LikelihoodPillsDataStorage_Value',
      'LikelihoodPillsDataAccess',
      'LikelihoodPillsDataAccess_Value',
      'ProgressNumber',
      'ProgressDate',
      'Threshold',
      'RiskRatingSummary',
      'RiskRecommendation',
      'Source',
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      }
    ],
    sort: [
      {
        field: 'ProgressDate',
        order: 'DESC',
      },
      {
        field: 'ProgressNumber',
        order: 'DESC',
      },
      {
        field: 'Threshold',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingOverviewBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      'AssessmentId',
      'ProductComponentId',
      'ImpactLevel',
      'Likelihood',
      'RiskRating',
      'PIIDataAccessId',
      'PHIDataAccessId',
      'PCIDataAccessId',
      'EmployeeProprietaryDataAccessId',
      'RiskRatingSummary',
      'RiskRecommendation',
      'LastUpdated',
      'AssessmentQuestionnaireId',
      'LikelihoodPillsDataAccess',
      'OffshoreDataAccessId',
      'OffshoreDataStorageId',
      "PublishedDate",
      "Source",
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [
      {
        field: "PublishedDate",
        order: "DESC"
      }
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "VendorId",
      "VendorName",
      "VendorDescription",
      "ProductName",
      "ProductDescription",
      "ProductUrl",
      "Environment",
      "SummaryDate",
      "ValidationTypeId",
      "ValidationTypeName",
      "LastUpdated",
      "Source",
      "DisplayExecutiveSummaryLink",
      "AssessmentType"
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'ClientName',
      'VendorName',
      'VendorDescription',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'Environment',
      'SummaryDate',
      'ValidationTypeId',
      'ValidationTypeName',
      'LastUpdated'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceReviewBodyForPortal = (id) => {

  return {
    "intent": "",
    "fields": [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "AssessmentQuestionnaireId",
      "Adequacy",
      "LikelihoodPillsDataAccess",
      "TierName",
      "AssessmentQuestionnaireQuestionResponseList",
      "QuestionText"
    ],
    "Criterion": [
      {
        "Field": "AssessmentId",
        "Operator": "=",
        "Value": id
      }
    ],
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
};

export const getEvidenceReviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'EvidenceReviewId',
      'ResultOfTesting',
      'EvidenceReviewed',
      'Requirement',
      'QuestionText',
      'DescriptionOfEvidence',
      'Outcome',
      'Outcome_Value',
      'ControlOrder',
      'ControlNumber',
      'ControlName',
      'QuestionnaireTemplateItemId',
      'TierName'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [
      {
        field: 'ControlOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingControlSummaryBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "AssessmentId",
      'Tier1Data',
      'Tier2Data',
      'Tier3Data',
      'Tier4Data'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingControlSummaryBody = (id) => {
  return {
    intent: '',
    fields: ['EnvironmentId', 'EnvironmentName', 'Tier1Data', 'Tier2Data', 'Tier3Data', 'Tier4Data'],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskFindingSummaryBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "SeverityId",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "TierName"
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskFindingSummaryBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskSummaryId',
      'Priority',
      'PriorityName',
      'RiskDescription',
      'ControlNumber',
      'ControlName',
      'StatusId',
      'Status',
      'CompletionDate',
      'RemediationStrategyAcceptByVendor',
      'AcceptedByVendor',
      'ContractuallyAgreedUponDate',
      'RecommendedDueDate',
      'ProposedRecommendation',
      'AssessmentResults',
      'TierName'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentByIdBody = (id) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'LastUpdated',
      'AssessmentStatus',
      'AssessmentStage',
      'AssessmentTypeId',
      'MasterStepName',
      'AssessmentStartDate',
      'DynVendorAccountId',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'PortalId',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'Source'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};


export const getModifiedList = (actualList, completedAssessmentsCount = 0) => {
  let list = actualList;
  if (!isEmpty(actualList) && actualList?.length > 0) {
    list = actualList?.map((it, index) => {
      let envList = [];
      if (!isNil(it?.EnvironmentList) && !isUndefined(it?.EnvironmentList)) {
        envList = it?.EnvironmentList?.map((env) => {
          const envName = env?.EnvironmentName?.replace(env?.VrasEnvironmentName, '');
          return {
            ...env,
            VrasEnvironmentName: isNil(env?.VrasEnvironmentName) ? env?.EnvironmentName?.split('-')[0] : env?.VrasEnvironmentName,
            EnvironmentName: envName?.split('-').length > 1 ? `${envName?.split('-')[1]}` : env?.EnvironmentName,
          };
        });
      }

      //Convert LastUpdated of portal 2.0 date format to legacy date format
      let parsedLastUpdated = null;
      if (it?.Source !== DYNAMIC) {
        parsedLastUpdated = format(parse(it?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');
      }
      else {
        parsedLastUpdated = it?.LastUpdated
      }

      return {
        ...it,
        DisplayVendorPercentage: String(it?.DisplayVendorPercentage) === '1',
        DisplayResultAndEvidence: String(it?.DisplayResultAndEvidenceLinks) === '1',
        SeqNo:
          !isNil(completedAssessmentsCount) && Number(completedAssessmentsCount) > 0
            ? `${index + 1}/${completedAssessmentsCount}`
            : `${index + 1}/${actualList?.length}`,
        VendorProgressPercent:
          String(it?.DisplayVendorPercentage) === '1' && !isNil(it?.PortalId) ? getPercentage(it?.CountOfResponses, it?.CountOfQuestions) : 0,
        LastActionDate: convertToLocalDateTime(parsedLastUpdated, 'MM/dd/yyyy'),
        LastActionTime: convertToLocalDateTime(parsedLastUpdated, 'h:mm a'),
        LastStatusActivity:
          !isEmpty(it) && !isEmpty(parsedLastUpdated)
            ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
              ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
              : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
            : null,
        UpdateTimeStamp: format(parse(parsedLastUpdated, 'MM/dd/yyyy HH:mm:ss', new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
        EnvironmentList: envList,
      };
    });
  }
  return list;
};


export const extractInformationFromDecryptedString = (inputString) => {
  const productIdRegex = /ProductVendorId:(\d+)::/;
  const assessmentIdRegex = /AssessmentId:(\d+)::/;
  const expiryRegex = /Expiry:(.+)/;

  const productIdMatch = inputString.match(productIdRegex);
  const assessmentIdMatch = inputString.match(assessmentIdRegex);
  const expiryMatch = inputString.match(expiryRegex);

  if (productIdMatch && assessmentIdMatch && expiryMatch) {
    const productVendorId = productIdMatch[1];
    const assessmentId = assessmentIdMatch[1];
    const expiryDate = expiryMatch[1];

    return {
      productVendorId: productVendorId,
      assessmentId: assessmentId,
      expiryDate: expiryDate
    };
  } else {
    return null; // Return null if required information cannot be extracted
  }
}