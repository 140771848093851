import { isEmpty } from "lodash";

//this decides idle timeout for application
export const IDLE_TIMER_TIMEOUT = 15 * 60 * 1000; //milliseconds
export const IDLE_TIMER_THROTTLE = 500;
//Setting below decides amount time in milliseconds. Other tabs are notified about the timeout on active tab.
export const IDLE_TIMER_SYNC_TIMERS = 100;

export const IDLE_TIMEOUT_MESSAGE = 'For security reasons, you have been logged out due to inactivity.';

export const LOGOUT_MESSAGE = 'Are you sure you want to logout?';

export const APP_CONFIG_KEYS = {
  ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX: 'ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX',
  ASSESSMENTS_OVERVIEW_RESULT_SUFFIX: 'ASSESSMENTS_OVERVIEW_RESULT_SUFFIX',
  ASSESSMENT_REQUEST_MAX_FILE_SIZE: 'ASSESSMENT_REQUEST_MAX_FILE_SIZE',
  CORL_EMAIL_VSRM: 'CORL_EMAIL_VSRM',
  ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD: 'ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD',
  DISPLAY_DEVELOPER_PORTAL: 'DISPLAY_DEVELOPER_PORTAL',
  DISPLAY_ZENDESK_STARTED_GUIDE: 'DISPLAY_ZENDESK_STARTED_GUIDE',
  DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS: 'DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS',
  DISPLAY_ZENDESK_SUPPORT_ARTICLES: 'DISPLAY_ZENDESK_SUPPORT_ARTICLES',
  EVIDENCE_DOCUMENT_MAX_FILE_SIZE: 'EVIDENCE_DOCUMENT_MAX_FILE_SIZE',
  EVIDENCE_DOCUMENT_MAX_FILES_TO_UPLOAD: 'EVIDENCE_DOCUMENT_MAX_FILES_TO_UPLOAD',
};
export const TRUE = '1';
export const FALSE = '0';

export const YES = 'YES';
export const NO = 'NO';

//The time window before which token will be updated (in seconds)
export const TOKEN_UPDATE_TIME_WINDOW = 900;

//The time interval to check for the token expiry (in miliseconds)
export const TOKEN_CHECK_TIME_INTERVAL = 300 * 1000;

export const NEW_NOTIFICATION_STATUS_ID = 1;
export const READ_NOTIFICATION_STATUS_ID = 2;
export const DISMISS_NOTIFICATION_STATUS_ID = 3;

export const YES_VALUE = 1;
export const NO_VALUE = 0;

//Legacy and protal 2.0 constants
export const DYNAMIC = 'Dynamic';
export const PORTAL_2 = 'Portal 2.0';

//Anonymization constants
export const ANONYMS_URL_PATH = "/anonymous"
export const TERMS_AND_CONDITIONS_CORL_URL_KEY='TermsOfServiceURL';
export const ABOUT_US_CORL_URL_KEY='AboutUsURL';
export const PRIVACY_POLICY_URL_KEY ='PrivacyPolicyURL'; 

export const FOOTER_TECHNICAL_CONTACT_US_EMAIL_KEY = 'FooterTechnicalContactUsEmail';
export const FOOTER_LEGAL_CONTACT_US_EMAIL_KEY='FooterLegalContactUsEmail';
export const NON_DISCLOSURE_AGREEMENT_KEY = 'NonDisclosureAgreementURL';

export const CORL_CLEARED_ASSESSMENT_TIMEOUT = 180000 // Set the timeout to 180 seconds (in milliseconds)
export const GENERATE_COPILOT_RESPONSE_TIMEOUT = 300000 // Set the timeout to 300 seconds (in milliseconds)

export const ASSESSMENT_STANDARD = "Standard";
export const ASSESSMENT_INCIDENT_RESPONSE = "Incident Response";
export const ASSESSMENT_CORL_CLEARED = "CORL Cleared";
export const ASSESSMENT_CLIENT_INITIATED = "Client Initiated";
export const ASSESSMENT_VENDOR_PROFILE_ASSESSMENT = "Vendor Profile Assessment";


export const getContactUsTechnicalSupportLink = (CUSTOMER_SUPPORT_EMAIL,orgName) => {
  if(isEmpty(CUSTOMER_SUPPORT_EMAIL)||isEmpty(orgName)){
    return ''
  }
  return `mailto:${CUSTOMER_SUPPORT_EMAIL}?subject=${orgName} Has a Support Question`;
}

export const  getContactUsLegalLink = (LEGAL_ENQUIRY_EMAIL,orgName)=> {
  if(isEmpty(LEGAL_ENQUIRY_EMAIL)||isEmpty(orgName)){
    return ''
  }
  return `mailto:${LEGAL_ENQUIRY_EMAIL}?subject=${orgName} Has a Legal Question`;
}

export const ESCAPE_QUOTE_REGEX = '\\"';
export const ASSESSMENT_TERMINATED = 1;
export const EXPEDITE_ASSESSMENT = 2;
export const EDIT_ESCALATED_ASSESSMENT = 3;
export const ASSESSMENT_PAUSED = 4;
export const ASSESSMENT_COMPLETE_NO_ACTION_REQUIRED = 5;
export const ASSESSMENT_COMPLETE_TERMINATED = 30;
export const ASSESSMENT_COMPLETED = 6;

export const ASSESSMENT_CORL_CLEARED_CLIENT_INITIATED = "CORL Cleared Client Initiated";

export const COPILOT_LABEL = 'Companion';

export const DATE_TYPE_RESPONSE_ID = 3;
export const DATE_FORMAT = 'MM/dd/yyyy';

export const PRE_ASSESSMENT_TYPEID = '8';

export const PARENT_QUESTION = 1;

export const APRYSE_WEBVIEWER_CORE_SCRIPT_PATH = '/webviewer/lib/core/webviewer-core.min.js';
